import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllJobsForCompany } from '../../redux/actions/Company/getAllJobsForCompany';
import JobTableHeader from '../../components/JobTable//JobTableHeader';
import JobTableInfoMessage from '../../components/JobTable/JobTableInfoMessage';
import JobTableBody from '../../components/JobTable/JobTableBody';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const JobTable = () => {
  const user = useSelector(state => state.user);
  const jobsFC = useSelector(state => state.jobsFC);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(getAllJobsForCompany({ userId: user.id }));
    }
  }, [user, dispatch]);

  const handleJobClose = async (jobId) => {
    if (confirm('Are you sure you want to close this vacancy?')) {
      try {
        const result = await axios.put(`/close-job/?jobId=${jobId}`)
        if (result.status === 200) {
          if (result.data.message === 'Update successful') {
            toast.success('Job closed successfully, refreshing!',
              {
                autoClose: 1500,
                onClose: () => {
                  dispatch(getAllJobsForCompany({ userId: user.id }));
                }
              }
            );  
            
          }else{
            toast.error('Nothing was update')
          }
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.error);
        } else {
          toast.error('An error occurred in the request. Please try again.')
        }
      }
    }else{
        return false;
    }
  }


  return (
    <div className="container mx-auto mt-8 mb-80 px-4 sm:px-6 lg:px-8">
      <JobTableHeader />
      <JobTableInfoMessage />
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Posting Title</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Num. candidates</th>
              <th colSpan={4} className="py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <JobTableBody jobs={jobsFC} user={user} handleJobClose={handleJobClose}/>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default JobTable;
