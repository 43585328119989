import Carousel from "../../components/Carousel/Carousel";

const ExperienceSection = ({ logos }) => (
  <section className="text-center my-8">
    <h3 className="text-xl lg:text-2xl font-bold text-gray-800">Proven experience by</h3>
    <div className="flex justify-center space-x-4 mt-6">
      <Carousel logos={logos} />
    </div>
  </section>
);

export default ExperienceSection;
