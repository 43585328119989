import React, { useEffect, useState } from 'react';
import imagen from "../../assets/LANDING/ILUSTRACIONES-RYSCONNECT-LATAM.png"
import logo from "../../assets/Logo/Logo-signin.jpeg"
import SignInIllustration from '../../components/SignIn/SignInIllustration';
import SignInHeader from '../../components/SignIn/SignInHeader';
import SignInForm from '../../components/SignIn/SignInForm';
import { useDispatch, useSelector } from 'react-redux';
import { authenticated } from '../../redux/actions/SignIn/postSignIn';
import { useNavigate } from 'react-router-dom';
import { setUserErrorNull } from '../../redux/actions/Error/setUserErrorNull';

const SignIn = () => {

    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user);
    const error = useSelector((state) => state.user.error);
    const navigate = useNavigate();

    const [form, setForm] = useState({
        email: '',
        password: ''
    });

    useEffect(() => {
        if (user && !user.error && user.isAuthenticated) {
            if (user.userType === 'Company' ) {
                if (!user.isCompleteInfo) {
                    navigate('/complete-company-profile');
                }else{
                    navigate(`/your-job/${user.id}`)
                }
            } else if (user.userType === 'Candidate') {
                if (!user.isCompleteInfo) {
                    navigate('/create-candidate');
                }else{
                    navigate('/my-application')
                }
            } else {
                navigate('/');
            }
        }
        if (error) {
            alert('Email or password is wrong or your not authorized!');
            dispatch(setUserErrorNull());
        }
    }, [user, error, navigate, dispatch]);

    const onHandleChange = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const onHandleSubmit = (event) => {
        event.preventDefault();
        dispatch(authenticated(form));
    };

    return (
        <div className="flex flex-col-reverse lg:flex-row justify-between items-center min-h-screen bg-gray-100">
            <SignInIllustration src={imagen} alt="Illustration of a man with a magnifying glass and another man using a laptop" />
            <div className="flex items-center justify-center w-full lg:w-1/2 h-full p-4 lg:p-0">
                <div className="w-full max-w-md p-8 space-y-8 bg-white shadow-lg rounded-lg">
                    <SignInHeader logoSrc={logo} title="Welcome Back to RysConnect" />
                    <SignInForm form={form} onHandleChange={onHandleChange} onHandleSubmit={onHandleSubmit} />
                </div>
            </div>
        </div>
    );
};


export default SignIn;
