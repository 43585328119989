import imagenTres from "../../assets/LANDING/HOME/SaaS Graphics/PNG/graphic8.png";
import candidateImg from "../../assets/LANDING/HOME/SaaS Graphics/PNG/graphic3.png";
import { Link } from 'react-router-dom';

const TopPerformersSection = () => (
  <div className="bg-blue-700 text-white py-10 text-center">
  <div className="flex flex-col lg:flex-row justify-center items-center space-y-6 lg:space-y-0 lg:space-x-10">
    <img src={candidateImg} alt="Lorena Garcia" className="rounded-full " width={720}/>
    <div className="text-left lg:max-w-xl mt-6 lg:mt-0">
      <h1 className="text-4xl lg:text-5xl mb-4">
        Make the best decision <span className="text-yellow-500">based on data.</span>
      </h1>
      <p className="text-xl lg:text-2xl mb-2">
        <strong>We evaluate each candidate with hard and soft skill tests specific to your vacancy and company.</strong>
      </p>
      <p className="text-xl lg:text-2xl mb-4">
        <strong>Compare them and find the ideal candidate without wasting time.</strong>
      </p>
      <button className="bg-yellow-500 text-blue-700 px-6 py-2 rounded-lg text-xl">
        <Link to="/signup">Sign Up</Link>
      </button>
    </div>
  </div>

  <div className="flex flex-col lg:flex-row justify-center items-center space-y-6 lg:space-y-0 lg:space-x-10">
    <div className="text-left lg:max-w-xl">
      <h2 className="text-3xl lg:text-4xl mb-4">
        <strong>Meet your next</strong> <span className="text-yellow-500"><strong>top performers.</strong></span>
      </h2>
      <p className="text-xl lg:text-2xl mb-4">
        <strong>Discover our curated talent pool, with over 15k tech workers across LATAM.</strong>
      </p>
      <button className="bg-yellow-500 text-blue-700 px-6 py-2 rounded-lg text-xl">
        <Link to="/signup">Sign Up</Link>
      </button>
    </div>
    <img src={imagenTres} alt="Rysconnect" className="" width={720} />
  </div>
</div>

);

export default TopPerformersSection;
