import React from 'react';

const SignInHeader = ({ logoSrc, title }) => {
    return (
        <>
            <div className="flex justify-center">
                <img src={logoSrc} alt="Logo" className="w-20 h-20" />
            </div>
            <h2 className="text-2xl font-bold text-center text-blue-600">{title}</h2>
        </>
    );
};

export default SignInHeader;
