import React from 'react';
import { FaLinkedin, FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
import logo from '../../assets/Logo/simbolo-SF-04.png';

const Footer = () => {
  return (
    <div className="bg-blue-700 text-white p-6 md:p-10 flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
      <div className="flex items-center space-x-2">
        <div className="rounded-full">
          <img src={logo} alt="Rysconnect Logo" className="h-10 w-10" />
        </div>
        <span className="text-xl font-bold">Rysconnect.</span>
      </div>
      <div className="text-center md:text-left">
        <p className="text-lg font-semibold">Do you want more information?</p>
        <p className="text-base">Be among the first to join and get unique loyalty benefits.</p>
      </div>
      <div className="text-center md:text-right">
        <p className="text-lg font-semibold mb-2">VISIT US:</p>
        <div className="flex justify-center md:justify-end space-x-4">
          <a href="https://www.youtube.com/@rysconnect" className="text-white">
            <FaYoutube size={24} />
          </a>
          <a href="https://www.linkedin.com/company/rysconnect-io/" className="text-white">
            <FaLinkedin size={24} />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61555537606972" className="text-white">
            <FaFacebookF size={24} />
          </a>
          <a href="https://www.instagram.com/rysconnect.io/" className="text-white">
            <FaInstagram size={24} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
