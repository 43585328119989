import tools6 from "../../assets/LANDING/PNG/06.png";
import { Link } from 'react-router-dom';

const EndToEndSection = () => (
  <div className="bg-white text-gray-800 py-10 px-6 lg:px-20">
    <div className="text-center mb-10">
      <h1 className="text-4xl lg:text-5xl font-bold mb-4">
        Your <span className="text-blue-600">End-to-End Solution</span> for<br /> Scaling Your Tech Team.
      </h1>
      <h2 className="text-xl lg:text-2xl text-blue-600 font-semibold mb-4">All the Power in One App.</h2>
      <p className="text-lg lg:text-xl mb-8">
        Just tell us what you’re looking for, and <br /> we’ll take care of everything else for you.
      </p>
      <div className="flex justify-center">
        <img src={tools6} alt="Candidate 1" className="object-cover" width={720}/>
      </div>
      <button className="bg-yellow-500 text-blue-700 px-6 py-2 rounded-lg text-xl mt-6">
        <Link target='_blank' to="https://calendly.com/sergio_razo_ryscode/30min">Contact Us</Link>
      </button>
    </div>
  </div>
);

export default EndToEndSection;
