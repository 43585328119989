import { useEffect } from 'react';
import { getCandidates } from '../../redux/actions/Recruiter/getCandidates';
import { useDispatch, useSelector } from 'react-redux'
import { getCandidateByFilters } from '../../redux/actions/Recruiter/getCandidatesByFilters';
import HireTalentHeader from '../../components/HireTalentComponents/HireTalentHeader';
import HireTalentContent from '../../components/HireTalentComponents/HireTalentContent';



const HireTalent = () => {


const candidates = useSelector(state=> state.candidates) 
const dispatch = useDispatch()

useEffect(()=>{
  dispatch(getCandidates())
},[])

if (!candidates) {
  return <p>Loading candidate details...</p>;
}

const searchCandidates = (name) =>{
  dispatch(getCandidates(name))
}

const filterCandidates = (filters) =>{
  dispatch(getCandidateByFilters(filters))
}

const resetCandidatesFilter = () =>{
  dispatch(getCandidates())
}


return (
  <div className="min-h-screen bg-white flex flex-col items-center p-4 md:p-8">
    <HireTalentHeader searchCandidates={searchCandidates} />
    <HireTalentContent 
      candidates={candidates} 
      filterCandidates={filterCandidates} 
      resetCandidatesFilter={resetCandidatesFilter} 
    />
  </div>
);
};

export default HireTalent
