import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { logout } from "../../redux/actions/Logout/Logout";
import imagen from "../../assets/Logo/simbolo-SF-04.png";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const onHandleClick = (event) => {
    dispatch(logout());
    navigate('/')
  };

  const handleMenuClick = () => {
    setIsOpen(false);
  };

  return (
    <header className="bg-black text-white">
      <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <div className="p-2 rounded-full">
            <img src={imagen} alt="RysConnect logo" className="w-12 h-12" />
          </div>
          <Link to="/" onClick={handleMenuClick}>
            <span className="text-lg font-bold">Rysconnect.</span>
          </Link>
        </div>
        <div className="block lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-white focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
        <nav
          className={`${
            isOpen ? "block" : "hidden"
          } lg:flex lg:space-x-6 lg:relative lg:top-auto lg:left-auto lg:right-auto lg:bg-transparent absolute top-16 left-0 right-0 bg-black z-10 lg:z-auto`}
        >
          {user?.isAuthenticated ? (
            <>
              {user.userType === "Admin-FLAS-1721" && (
                <>
                  <a
                    className="block lg:inline-block hover:text-gray-400 px-4 py-2"
                    onClick={handleMenuClick}
                  >
                    <Link to="/admin-companies">Companies</Link>
                  </a>

                  <a
                    className="block lg:inline-block hover:text-gray-400 px-4 py-2"
                    onClick={handleMenuClick}
                  >
                    <Link to="/admin-jobs">Admin</Link>
                  </a>

                  <a
                    className="block lg:inline-block text-orange-400 hover:text-orange-700 cursor-pointer px-4 py-2"
                    onClick={handleMenuClick}
                  >
                    {user.name}
                  </a>
                </>
              )}

              {user.userType === "Candidate" && user.isCompleteInfo && (
                <>
                  <a
                    className="block lg:inline-block hover:text-gray-400 px-4 py-2"
                    onClick={handleMenuClick}
                  >
                    <Link to="/job-positions-list">Positions</Link>
                  </a>
                  <a
                    className="block lg:inline-block hover:text-gray-400 px-4 py-2"
                    onClick={handleMenuClick}
                  >
                    <Link to="/my-application">My positions</Link>
                  </a>
                  <a
                    className="block lg:inline-block text-orange-400 hover:text-orange-700 cursor-pointer px-4 py-2"
                    onClick={handleMenuClick}
                  >
                    <Link to={`/profile-candidates`}>
                      {user.name}
                    </Link>
                  </a>
                </>
              )}


              {user.userType === "Company" && user.isCompleteInfo && (
                <>
                  <a
                    className="block lg:inline-block hover:text-gray-400 px-4 py-2"
                    onClick={handleMenuClick}
                  >
                    <Link to="/hire-talent">Hire Talent</Link>
                  </a>
                  <a
                    className="block lg:inline-block hover:text-gray-400 px-4 py-2"
                    onClick={handleMenuClick}
                  >
                    <Link to="/register-job">Register Job</Link>
                  </a>
                  <a
                    className="block lg:inline-block hover:text-gray-400 px-4 py-2"
                    onClick={handleMenuClick}
                  >
                    <Link to={`/your-job/${user.id}`}>My Jobs</Link>
                  </a>
                  <a
                    className="block lg:inline-block text-orange-400 hover:text-orange-700 cursor-pointer px-4 py-2"
                    onClick={handleMenuClick}
                  >
                    <Link to={`/company-profile`}>{user.name}</Link>
                  </a>
                </>
              )}

              <a
                className="block lg:inline-block text-blue-400 hover:text-blue-700 cursor-pointer px-4 py-2"
                onClick={() => {
                  handleMenuClick();
                  onHandleClick();
                }}
              >
                LogOut
              </a>
            </>
          ) : (
            <>
                  <a
                    className="block lg:inline-block hover:text-gray-400 px-4 py-2"
                    onClick={handleMenuClick}
                  >
                    <Link to="/job-positions-list">Positions</Link>
                  </a>
              <Link to="/signin">
                <a
                  className="block lg:inline-block text-white border border-white hover:bg-white hover:text-black px-4 py-2 mx-2"
                  onClick={handleMenuClick}
                >
                  Log In
                </a>
              </Link>
              <Link to="/signup">
                <a
                  className="block lg:inline-block text-white bg-orange-400 hover:bg-orange-500 px-4 py-2 mx-2"
                  onClick={handleMenuClick}
                >
                  Register Now
                </a>
              </Link>
              <Link target="_blank" to="https://calendly.com/sergio_razo_ryscode/30min">
                <a
                  className="block lg:inline-block text-white bg-blue-400 hover:bg-blue-500 px-4 py-2 mx-2"
                  onClick={handleMenuClick}
                >
                  Contact Us
                </a>
              </Link>
              <a
                className="block lg:inline-block text-white hover:text-gray-400 px-4 py-2 mx-2"
                onClick={handleMenuClick}
              >
              </a>
            </>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
