import React from 'react';
import { Link } from 'react-router-dom';


function JobCard({ job }) {
    return (
        <Link className="p-4 bg-white rounded shadow-md flex flex-col sm:flex-row items-start sm:items-center" to={`/job-description/${job.id}`}>
            <div className="w-full">
                <div className='flex justify-between'>
                    <h2 className="text-xl font-semibold mb-2">{job.jobName}</h2>
                    <span className="font-semibold mb-2 text-sm p-4 rounded-lg bg-black text-white">{job.tbl_company.companyName}</span>
                </div>
                <hr  className='mt-3 mb-3 border-gray-200'/>
                <div className="flex flex-wrap">
                    <div className="flex flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
                        <span className="block font-semibold">Technologies</span>
                        <div className='w-full flex flex-wrap'>
                        {job?.mustHaveTechnologies.map((tech)=>(<span className="text-gray-600 m-1">{`${tech}`}</span>))}
                        </div>
                    </div>
                    <div className="flex flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
                        <span className="block font-semibold">Salary (USD)</span>
                        <span className="text-gray-600">{job.salary}</span>
                    </div>
                    <div className="flex flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
                        <span className="block font-semibold">Seniority</span>
                        <span className="text-gray-600">{job.seniority}</span>
                    </div>
                    <div className="flex flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
                        <span className="block font-semibold">Working Scheme</span>
                        <span className="text-gray-600">{job.workingScheme}</span>
                    </div>
                    <div className="flex flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
                        <span className="block font-semibold">English Level</span>
                        <span className="text-gray-600">{job.englishLevel}</span>
                    </div>
                    <div className="flex flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
                        <span className="block font-semibold">Location</span>
                        <span className="inline-block px-2 py-1 border border-blue-400 rounded-full text-blue-400">
                            {job.locationAvailability}
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default JobCard;

