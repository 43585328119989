import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCompanies } from '../../redux/actions/Company/getCompanies';

function CompaniesForAdmin() {

const user = useSelector(state => state.user)
const companies = useSelector(state => state.companies)
const dispatch = useDispatch()

useEffect(()=>{
if (user) {
    
    dispatch(getCompanies(user.id))
}
},[dispatch, user])


if (!companies) {
return (
    <div className="flex items-center justify-center min-h-screen">
        <p className="text-xl font-semibold text-gray-700">Loading company details...</p>
    </div>
);
}



return (
    <div className="flex flex-col items-center mt-10 mb-auto">
    
    <h1 className=' text-3xl mb-10 font-bold'>List of companies</h1>
    
    <div className="flex flex-wrap justify-center">
        {companies.map((company) => (
        <div
            key={company.id}
            className="flex flex-col items-center border border-gray-300 shadow-lg rounded-lg p-6 m-4 hover:bg-blue-100 hover:shadow-xl transition duration-300 w-64 bg-white"
        >
            <span className="font-bold text-lg text-gray-800 mb-4">{company.companyName}</span>
            
            <Link to={`/admin-companies-jobs/${company.id}`}>
            <button className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
                Select
            </button>
            </Link>
        </div>
        ))}
    </div>
    </div>
);
}

export default CompaniesForAdmin;


