import React from 'react';

const SignInForm = ({ form, onHandleChange, onHandleSubmit }) => {
    return (
        <form className="mt-8 space-y-6" onSubmit={onHandleSubmit}>
            <div className="rounded-md shadow-sm">
                <div>
                    <label htmlFor="email" className="sr-only">Email</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="relative block w-full px-3 py-2 border border-gray-300 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                        placeholder="Your email"
                        value={form.email}
                        onChange={onHandleChange}
                    />
                </div>
                <div>
                    <label htmlFor="password" className="sr-only">Password</label>
                    <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="relative block w-full px-3 py-2 border border-gray-300 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                        placeholder="Password"
                        value={form.password}
                        onChange={onHandleChange}
                    />
                </div>
            </div>
            <div className="flex items-center justify-between">
                {/* Puedes agregar un link de recuperación de contraseña aquí si es necesario */}
            </div>
            <div>
                <button
                    type="submit"
                    className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-yellow-500 border border-transparent rounded-md group hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                >
                    Sign In
                </button>
            </div>
        </form>
    );
};

export default SignInForm;
