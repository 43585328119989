import imagenDos from "../../assets/LANDING/HOME/SaaS Graphics/PNG/graphic4.png";
import { Link } from 'react-router-dom';

const CandidateSection = () => (
  <section className="flex flex-col justify-center lg:flex-row items-center my-8 px-6 lg:px-20">
    <img src={imagenDos} alt="Candidates" className="" width={720} />
    <div className="lg:ml-10 mt-6 lg:mt-0 text-left">
      <h2 className="text-lg lg:text-2xl font-bold text-gray-800">
        <span className="text-blue-600"><strong>Select the Candidates who</strong></span><br />
        <span className="text-orange-600"><strong>best match your Job Opening.</strong></span>
      </h2>
      <button className="bg-orange-400 text-white py-2 px-4 mt-6 rounded">
        <Link to="/signup">Sign Up</Link>
      </button>
    </div>
  </section>
);

export default CandidateSection;
