import HeaderSection from "../../components/Landing/HeaderSection";
import DescriptionSection from "../../components/Landing/DescriptionSection";
import ExperienceSection from "../../components/Landing/ExperienceSection";
import BenefitsSection from "../../components/Landing/BenefitsSection";
import FixingSection from "../../components/Landing/FixingSection";
import RecruitmentSection from "../../components/Landing/RecruitmentSection";
import CandidateSection from "../../components/Landing/CandidateSection";
import TopPerformersSection from "../../components/Landing/TopPerformersSection";
import EndToEndSection from "../../components/Landing/EndToEndSection";

import carrusel1 from "../../assets/Logosclientes/AgileEngine.png";
import carrusel2 from "../../assets/Logosclientes/ArkusNexus.png";
import carrusel3 from "../../assets/Logosclientes/DataCo.jpg";
import carrusel4 from "../../assets/Logosclientes/Encora.jpg";
import carrusel5 from "../../assets/Logosclientes/HouseCallPro.png";
import carrusel6 from "../../assets/Logosclientes/Inceptio.jpg";
import carrusel7 from "../../assets/Logosclientes/Integon.jpg";
import carrusel8 from "../../assets/Logosclientes/Michelada.png";
import carrusel9 from "../../assets/Logosclientes/Mirigos.jpg";
import carrusel10 from "../../assets/Logosclientes/Moonshot.jpg";
import carrusel11 from "../../assets/Logosclientes/NESTO.jpg";
import carrusel12 from "../../assets/Logosclientes/Never8.jpg";
import carrusel13 from "../../assets/Logosclientes/Plannet.png";
import carrusel14 from "../../assets/Logosclientes/Syscap.jpg";
import carrusel15 from "../../assets/Logosclientes/T2O.jpg";
import carrusel16 from "../../assets/Logosclientes/Teamwork.jpg";

const Landing = () => {

  const logos = [
    { src: carrusel1, alt: 'Integon' },
    { src: carrusel2, alt: 'Housecall Pro' },
    { src: carrusel3, alt: 'Encora' },
    { src: carrusel4, alt: 'Arkus Nexus' },
    { src: carrusel5, alt: 'Housecall Pro' },
    { src: carrusel6, alt: 'Encora' },
    { src: carrusel7, alt: 'Arkus Nexus' },
    { src: carrusel8, alt: 'Housecall Pro' },
    { src: carrusel9, alt: 'Encora' },
    { src: carrusel10, alt: 'Arkus Nexus' },
    { src: carrusel11, alt: 'Housecall Pro' },
    { src: carrusel12, alt: 'Encora' },
    { src: carrusel13, alt: 'Arkus Nexus' },
    { src: carrusel14, alt: 'Housecall Pro' },
    { src: carrusel15, alt: 'Encora' },
    { src: carrusel16, alt: 'Arkus Nexus' },
  ];

  return (
    <div>
      <main className="flex flex-col items-center bg-white px-6 md:px-10 lg:px-20 py-8">
        <HeaderSection />
        <DescriptionSection />
        <ExperienceSection logos={logos} />
        <BenefitsSection />
      </main>
      <FixingSection />
      <RecruitmentSection />
      <CandidateSection />
      <TopPerformersSection />
      <EndToEndSection />
    </div>
  );
}

export default Landing;
