import React from 'react';
import { Link } from 'react-router-dom';

const ApplicationCard = ({ application }) => {
  const statusClass = application.application.status === 'Applied'
    ? 'bg-blue-200 text-blue-900'
    : application.application.status === 'Answered'
    ? 'bg-green-200 text-green-900'
    : application.application.status === 'Seen'
    ? 'bg-yellow-200 text-yellow-900'
    : application.application.status === 'Revision'
    ? 'bg-purple-200 text-purple-900'
    : application.application.status === 'Rejected'
    ? 'bg-red-200 text-red-900'
    : application.application.status === 'Contacted'
    ? 'bg-indigo-200 text-indigo-900'
    : application.application.status === 'Accepted'
    ? 'bg-teal-200 text-teal-900'
    : 'bg-gray-200 text-gray-900';

    // [['Applied', 'Answered','Seen', 'Refused', 'Accepted', 'Contacted']]
  return (
    <div className="bg-white p-6 shadow-lg rounded-lg border border-gray-200 hover:shadow-2xl transition-shadow duration-300 relative">
      <h3 className="text-2xl font-semibold text-gray-800 mb-2">{application.job.jobName}</h3>
      <span className="text-sm text-gray-500 mb-4 block">{application.company}</span>

      {application.application.statusOfTest ? (
        <div className="flex justify-between items-center mb-4 flex-col">
          <p className="text-green-700 font-bold">Test completed</p>


          <div className="w-full bg-gray-200 rounded-full">
            <div className={`
              ${application.application.score <= 50 ? 'bg-red-600' : application.application.score <= 79 ? 'bg-yellow-500' : 'bg-green-600'}
              text-xs font-bold text-white text-center p-0.5 leading-none rounded-full
            `} 
            style={{ width: `${application.application.score}%` }}>
              {application.application.score}%
            </div>
        </div>


        

        </div>
        
      ) : (
        <div className="flex justify-between items-center mb-4">
          <Link to={`${application.job.assessmentUrl}`} target="_blank" rel="noopener noreferrer">
            <button className="bg-gradient-to-r from-green-500 to-green-700 text-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              Take Test
            </button>
          </Link>
          <p className="text-red-700 font-bold">Please complete the test</p>
        </div>
      )}

      {/* Etiqueta de estado estilizada */}
      <span
        className={`absolute top-4 right-4 px-4 py-2 rounded-full text-sm font-bold ${statusClass} shadow-xl`}
      >
        {application.application.status.charAt(0).toUpperCase() + application.application.status.slice(1)}
      </span>
    </div>
  );
};

export default ApplicationCard;
