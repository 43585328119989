import { Link } from 'react-router-dom';

const BenefitsSection = () => (
  <section className="text-center my-8 bg-blue-700 text-white py-6 w-full">
    <h3 className="text-xl lg:text-2xl font-bold">
      Start experiencing the benefits,<br />
      post your job opening for free!
    </h3>
    <button className="bg-orange-400 text-white py-2 px-4 mt-6 rounded">
      <Link target='_blank' to="https://calendly.com/sergio_razo_ryscode/30min">Contact Us</Link>
    </button>
  </section>
);

export default BenefitsSection;
