import imagenUno from "../../assets/LANDING/HOME/SaaS Graphics/PNG/graphic6.png";

const FixingSection = () => (
  <section className="text-center my-8 items-center px-6 lg:px-20">
    <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800">
      Traditional recruitment sucks,<br />
      <span className="text-blue-700">so we are fixing it!</span>
    </h1>
    <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-blue-700 mt-6">
      Join Our Network of Tech Jobs
    </h2>
    <p className="text-xl md:text-2xl lg:text-3xl text-gray-800 mt-6">
      Expand your horizons and <span className="text-orange-400">let the talent come to you.</span>
    </p>
    <p className="text-md lg:text-2xl text-gray-600 mt-4">
      No more high-cost headhunting or unsuitable candidates.
    </p>
    <img src={imagenUno} alt="Post and Position Your Job Openings" className="mx-auto " width={720} height={500}/>
    <h2 className="text-xl lg:text-2xl font-bold text-orange-400 mt-2">Post and Position Your Job Openings.</h2>
  </section>
);

export default FixingSection;
