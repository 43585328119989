import { Link } from 'react-router-dom';
import imagen from "../../assets/LANDING/PNG/01.png";

const DescriptionSection = () => (
  <section className="text-center my-8 flex flex-col lg:flex-row lg:items-center">
    <img src={imagen} alt="Evaluate Candidates" className="mx-auto lg:w-1/2" />
    <div className="mt-6 lg:mt-0 lg:ml-10">
      <p className="text-lg lg:text-xl text-gray-700">
        Tell us what you're looking for,<br />
        and we'll handle the search for you.<br />
        <strong>All the power of a world-class</strong><br />
        <strong>IT Recruitment team in a SaaS.</strong>
      </p>
      <button className="bg-orange-400 text-white py-2 px-4 mt-6 rounded">
        <Link to="/signup">Sign Up</Link>
      </button>
    </div>
  </section>
);

export default DescriptionSection;
