import { Link } from 'react-router-dom';

const RecruitmentSection = () => (
  <section className="text-center my-8 bg-gray-100 py-6 w-full">
    <h3 className="text-xl lg:text-2xl font-bold text-gray-800">
      We recruit <span className="text-blue-700">24/7</span> while you focus on growing your startup!
    </h3>
    <p className="text-md lg:text-xl text-gray-600 mt-4">
      Through our algorithm, receive automatic suggestions for new candidates.
    </p>
    <button className="bg-orange-400 text-white py-2 px-4 mt-6 rounded">
      <Link to="/signup">Start Hiring</Link>
    </button>
  </section>
);

export default RecruitmentSection;
