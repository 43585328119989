import React from 'react';
import Select from 'react-select';

const SelectMainTech = ({ errors, form, mainTech, handleSelectChange, placeholder, error }) => {
    return (
<div className="col-span-1">
              <label className="block text-gray-700">
                Main Tech <span className="text-red-500">*</span>
              </label>{errors.mainTech && (
                <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                  <span>{errors.mainTech}</span>
                </div>
              )}
              <Select
              name="mainTech"
              isMulti
              value={form.mainTech?.map(tech => ({ value: tech, label: tech }))}
              onChange={handleSelectChange}
              options={[
                { value: 'JavaScript', label: 'JavaScript' },
                { value: 'TypeScript', label: 'TypeScript' },
                { value: 'Python', label: 'Python' },
                { value: 'Java', label: 'Java' },
                { value: 'C#', label: 'C#' },
                { value: 'C++', label: 'C++' },
                { value: 'PHP', label: 'PHP' },
                { value: 'Ruby', label: 'Ruby' },
                { value: 'Go', label: 'Go' },
                { value: 'Swift', label: 'Swift' },
                { value: 'Kotlin', label: 'Kotlin' },
                { value: 'Rust', label: 'Rust' },
                { value: 'Scala', label: 'Scala' },
                { value: 'Elixir', label: 'Elixir' },
                { value: 'Perl', label: 'Perl' },
                { value: 'Haskell', label: 'Haskell' },
                { value: 'Objective-C', label: 'Objective-C' },
                { value: 'Dart', label: 'Dart' },
                { value: 'SQL', label: 'SQL' },
                { value: 'MySQL', label: 'MySQL' },
                { value: 'PostgreSQL', label: 'PostgreSQL' },
                { value: 'SQL Server', label: 'SQL Server' },
                { value: 'R', label: 'R' },
                { value: 'MATLAB', label: 'MATLAB' },
                { value: 'Lua', label: 'Lua' },
                { value: 'Groovy', label: 'Groovy' },
                { value: 'Clojure', label: 'Clojure' },
                { value: 'F#', label: 'F#' },
                { value: 'Shell', label: 'Shell' },
                { value: 'PowerShell', label: 'PowerShell' },
                { value: 'HTML', label: 'HTML' },
                { value: 'CSS', label: 'CSS' },
                { value: 'Sass', label: 'Sass' },
                { value: 'Less', label: 'Less' },
                { value: 'SQL', label: 'SQL' },
                { value: 'NoSQL', label: 'NoSQL' },
                { value: 'GraphQL', label: 'GraphQL' },
                { value: 'XML', label: 'XML' },
                { value: 'JSON', label: 'JSON' },
                { value: 'Markdown', label: 'Markdown' },
                { value: 'HTML', label: 'HTML' },
                { value: 'CSS', label: 'CSS' },
                { value: 'Sass', label: 'Sass' },
                { value: 'Less', label: 'Less' },
                { value: 'Bootstrap', label: 'Bootstrap' },
                { value: 'TailwindCSS', label: 'TailwindCSS' },
                { value: 'Bulma', label: 'Bulma' },
                { value: 'Foundation', label: 'Foundation' },
                { value: 'Materialize', label: 'Materialize' },
                { value: 'Semantic UI', label: 'Semantic UI' },
                { value: 'jQuery', label: 'jQuery' },
                { value: 'Backbone.js', label: 'Backbone.js' },
                { value: 'AngularJS', label: 'AngularJS' },
                { value: 'Vue.js', label: 'Vue.js' },
                { value: 'Svelte', label: 'Svelte' },
                { value: 'Ember.js', label: 'Ember.js' },
                { value: 'Redux', label: 'Redux' },
                { value: 'MobX', label: 'MobX' },
                { value: 'Next.js', label: 'Next.js' },
                { value: 'Nuxt.js', label: 'Nuxt.js' },
                { value: 'Gatsby', label: 'Gatsby' },
                { value: 'Grunt', label: 'Grunt' },
                { value: 'Gulp', label: 'Gulp' },
                { value: 'Webpack', label: 'Webpack' },
                { value: 'Parcel', label: 'Parcel' },
                { value: 'Rollup', label: 'Rollup' },
                { value: 'Babel', label: 'Babel' },
                { value: 'Eslint', label: 'Eslint' },
                { value: 'Prettier', label: 'Prettier' },
                { value: 'Jest', label: 'Jest' },
                { value: 'Mocha', label: 'Mocha' },
                { value: 'Chai', label: 'Chai' },
                { value: 'Enzyme', label: 'Enzyme' },
                { value: 'Cypress', label: 'Cypress' },
                { value: 'Protractor', label: 'Protractor' },
                { value: 'Selenium', label: 'Selenium' },
                { value: 'Puppeteer', label: 'Puppeteer' },
                { value: 'Storybook', label: 'Storybook' },
                { value: 'GraphQL', label: 'GraphQL' },
                { value: 'Apollo', label: 'Apollo' },
                { value: 'Relay', label: 'Relay' },
                { value: 'Axios', label: 'Axios' },
                { value: 'Fetch', label: 'Fetch' },
                { value: 'Firebase', label: 'Firebase' },
                { value: 'AWS', label: 'AWS' },
                { value: 'Azure', label: 'Azure' },
                { value: 'React', label: 'React' },
                { value: 'React Native', label: 'React Native' },
                { value: 'Angular', label: 'Angular' },
                { value: 'Vue', label: 'Vue' },
                { value: 'Svelte', label: 'Svelte' },
                { value: 'Ember', label: 'Ember' },
                { value: 'Backbone', label: 'Backbone' },
                { value: 'Knockout', label: 'Knockout' },
                { value: 'D3.js', label: 'D3.js' },
                { value: 'Chart.js', label: 'Chart.js' },
                { value: 'Highcharts', label: 'Highcharts' },
                { value: 'Leaflet', label: 'Leaflet' },
                { value: 'Mapbox', label: 'Mapbox' },
                { value: 'Google Maps', label: 'Google Maps' },
                { value: 'OpenLayers', label: 'OpenLayers' },
                { value: 'Three.js', label: 'Three.js' },
                { value: 'A-Frame', label: 'A-Frame' },
                { value: 'Cesium', label: 'Cesium' },
                { value: 'Leaflet', label: 'Leaflet' },
                { value: 'Mapbox', label: 'Mapbox' },
                { value: 'Google Maps', label: 'Google Maps' },
                { value: 'OpenLayers', label: 'OpenLayers' },
                { value: 'REST', label: 'REST' },
                { value: 'GCP', label: 'GCP' },
                { value: 'Heroku', label: 'Heroku' },
                { value: 'Netlify', label: 'Netlify' },
                { value: 'Vercel', label: 'Vercel' },
                { value: 'Docker', label: 'Docker' },
                { value: 'Kubernetes', label: 'Kubernetes' },
                { value: 'CI/CD', label: 'CI/CD' },
                { value: 'Jenkins', label: 'Jenkins' },
                { value: 'Travis CI', label: 'Travis CI' },
                { value: 'CircleCI', label: 'CircleCI' },
                { value: 'GitHub Actions', label: 'GitHub Actions' },
                { value: 'Bitbucket Pipelines', label: 'Bitbucket Pipelines' },
              ]}
              className="block w-full"
            />
            </div>
    );
}

export default SelectMainTech;
