import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



const Carousel = ({logos})=>{
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };
    
    return(
        <section className="bg-white py-10 mb-10">
        <div className="max-w-7xl mx-auto px-4">
          <Slider {...settings}>
            {logos.map((logo, index) => (
              <div key={index} className="p-2 h-20 m-6">
                <img src={logo.src} alt={logo.alt} className="mx-auto max-w-full h-20"  />
              </div>
            ))}
          </Slider>
        </div>
      </section>
    )
}

export default Carousel