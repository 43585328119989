import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import Modalinfo from '../../components/CandidateInfo/Modalinfo';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const statusOptions = [
    { value: 'Applied', label: 'Applied' },
    { value: 'Answered', label: 'Answered' },
    { value: 'Seen', label: 'Seen' },
    { value: 'Rejected', label: 'Rejected' },
    { value: 'Contacted', label: 'Contacted' },
    { value: 'Accepted', label: 'Accepted' }
];

const VacancyDetail = ({ vacancyName }) => {
    const { id } = useParams(); // id del trabajo
    const user = useSelector(state => state.user);
    const [candidates, setCandidates] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [candidateStatuses, setCandidateStatuses] = useState({}); // Estado para almacenar los valores seleccionados

    useEffect(() => {
        // Fetch data from API endpoint
        if (user) {
            axios.get(`applications/?jobId=${id}&userId=${user.id}`).then(response => {
                const fetchedCandidates = response.data;
                setCandidates(fetchedCandidates);
                fetchedCandidates.forEach(candidate => {
                    setCandidateStatuses(prevState => ({
                        ...prevState,
                        [candidate.candidate.id]: candidate.application.status || null,
                    }))
                })
            })
        }
    }, [id, user]);

    const handleOpenModal = async (candidate) => {
        //Realizar la busqueda y luego asignar el valor
        // /candidate/
        const response = await axios.get(`candidate/${candidate.id}`);
        
        
        if (response.status === 200) {
            const candidate = response.data;
            setSelectedCandidate(candidate);
            setIsOpen(true);
        }else{
            toast.error('An error occurred')
        }
    };

    const handleStatusChange = async (selectedOption, candidateId, applicationId) => {
        
        setCandidateStatuses(prevState => ({
            ...prevState,
            [candidateId]: selectedOption  ? selectedOption.value : null,
        }));

        //Update a la base datos
        try {
            const result = await axios.put(`/change-application-status/${applicationId}`, {status: selectedOption.value})
            if (result.status === 200) {
                toast.success('Status updated successfully');
            } else{
                toast.error(result.data.error || 'An error occurred.');
            }
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.error || 'An error occurred.');
            } else {
                toast.error("An error occurred in the request. Please try again.");
            }
        }
        
    };


    const customStyles = (candidateId) => ({
        control: (provided, state) => ({
            ...provided,
            minWidth: state.isFocused || candidateStatuses[candidateId] ? '150px' : '100px',
            transition: 'min-width 0.3s ease',
            borderColor: state.isFocused ? '#2563EB' : provided.borderColor,
            boxShadow: state.isFocused ? '0 0 0 1px #2563EB' : provided.boxShadow,
            '&:hover': {
                borderColor: state.isFocused ? '#2563EB' : provided.borderColor,
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#2563EB' : state.isFocused ? '#BFDBFE' : provided.backgroundColor,
            color: state.isSelected ? 'white' : provided.color,
            '&:hover': {
                backgroundColor: state.isSelected ? '#2563EB' : '#BFDBFE',
                color: state.isSelected ? 'white' : provided.color,
            },
        }),
        menu: (provided) => ({
            ...provided,
            maxHeight: 120,
            overflowY: 'auto',
            zIndex: 1000,
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 1000,
        }),
    });

    return (
        <div className="container mx-auto mt-10 mb-auto">
            <h1 className="text-3xl font-bold mb-6 text-center">{vacancyName}</h1>
            <table className="min-w-full bg-white border rounded-lg overflow-hidden">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="py-3 px-4 text-left">Full name</th>
                        <th className="py-3 px-4 text-left">Mail</th>
                        <th className="py-3 px-4 text-left">Test Status</th>
                        <th className="py-3 px-4 text-left">Score</th>
                        <th className="py-3 px-4 text-left">Status</th>
                        <th colSpan={2} className="py-3 px-4 text-center">Report</th>
                    </tr>
                </thead>
                <tbody>
                    {candidates
                        .slice()
                        .sort((a, b) => (b.application.score || -Infinity) - (a.application.score || -Infinity))
                        .map(candidate => (
                            <tr key={candidate.candidate.id} className="border-t relative">
                                <td className="py-3 px-4">{candidate.application.fullName}</td>
                                <td className="py-3 px-4">{candidate.candidate.tbl_user.email}</td>
                                <td className="py-3 px-4">
                                    {candidate.application.statusOfTest ? 'Completed' : 'Pending'}
                                </td>
                                <td className="py-3 px-4">{candidate.application.score || 'N/A'}</td>
                                <td className="py-4 px-4">
                                    {candidate.application.status === 'Applied' ? 'Applied': (<Select
                                        
                                        name={`vacancy-${candidate.candidate.id}`}
                                        options={statusOptions.filter(option => !['Applied', 'Answered', 'Seen'].includes(option.value))}
                                        styles={customStyles(candidate.id)}
                                        onChange={(selectedOption) => handleStatusChange(selectedOption, candidate.candidate.id, candidate.application.id)}
                                        value={
                                            statusOptions.find((option) => 
                                                option.value === candidateStatuses[candidate.candidate.id]
                                            )
                                            }
                                        isSearchable={true}
                                        menuPortalTarget={document.body}
                                    />)}
                                    
                                </td>
                                <td className="py-3 px-4">
                                    {!candidate.application.score ? '' : (
                                        <Link to={`/reporting-ev/${candidate.candidate.id}/${id}`}>
                                            <button className="text-white bg-blue-600 p-2 rounded-xl">
                                                View evaluation report
                                            </button>
                                        </Link>
                                    )}
                                </td>
                                <td className="py-3 px-4">
                                    <button className="text-white bg-purple-600 p-2 rounded-xl" onClick={() => handleOpenModal(candidate.candidate)}>
                                        View candidate info
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                {selectedCandidate && <Modalinfo candidate={selectedCandidate} />}
            </Modal>
            <ToastContainer />
        </div>
    );
};

export default VacancyDetail;
